.Toastify__toast-container {
  width: 360px;
}

.toasterv2 {
  border-radius: 4px !important;
  padding: 12px 20px !important;
  line-height: 1.6em !important;
  z-index: 99999 !important;
}

.Toastify__toast-body {
  white-space: break-spaces;
}

.toasterv2-success {
  color: #999 !important;
  background-color: #FFF !important;
}

.toasterv2-error, .Toastify__toast--info.toasterv2-error {
  color: #FFF !important;
  background-color: red !important;
}

.toasterv2-warn {
  color: #FFF !important;
  background-color: darkorange !important;
}

.toasterv2 a {
  text-decoration: none;
  border-bottom: 1px dashed #CCC;
}

.toasterv2 button.Toastify__close-button {
  color: rgba(0, 0, 0, 0.4) !important;
  padding-left: 21px !important;
  margin-right: -11px !important;
  margin-top: -7px !important;
}

 .toasterv2-error button.Toastify__close-button {
  color: rgba(255, 255, 255, 0.8) !important;
 }

 .toasterv2-warn button.Toastify__close-button {
  color: rgba(255, 255, 255, 1) !important;
 }

 .toast-promo {
  background-color: #00c300;
  text-align: center;
  font-weight: bold;
  line-height: 2em;
  border-radius: 4px !important;
}

.Toastify__toast-container--top-center {
  transform: translateY(-46px) !important;
  text-align: center;
  width: initial;
  max-width: 500px;
  left: calc(50% - 80px)
}

.Toastify__toast-container--top-center  .Toastify__toast {
  box-shadow: none;    
  min-height: 42px;
}

.Toastify__toast-container--top-center .Toastify__close-button  {
  position: relative;
  top: 31px;
  right: 7px;
}


.Toastify__toast-container--top-center  .Toastify__toast {
  box-shadow: none;    
  padding: 0;
  margin: 0;
  height: 63px;
  line-height: 1em;
}

.Toastify__toast-container--top-center .Toastify__toast-body {
  padding-top: 20px;
}
    
