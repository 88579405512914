body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

.show-confidence .low-confidence {
  color: red;
}

.MuiTooltip-popper {
  z-index: 9999999;
}

.speaker-name {
  display: none;
}

.hide-speakers .speaker-cont {
  display: none;
}

.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translateX(0);
  backface-visibility: hidden;
}

.small-shake {
  animation: smallshake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translateX(0);
  backface-visibility: hidden;
}

body.beacon-lifted .BeaconFabButtonFrame,
body.beacon-lifted .BeaconContainer {
  transform: translate(-5px, -178px) scale(1) !important
}

@media print{
   .noprint{
       display:none;
   }
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-2px);
  }

  20%,
  80% {
    transform: translateX(4px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-8px);
  }

  40%,
  60% {
    transform: translateX(8px);
  }
}

@keyframes smallshake {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-3px);
  }

  40%,
  60% {
    transform: translateX(3px);
  }
}


@keyframes Pulsate {
  from { opacity: 1; }
  50% { opacity: .5; }
  to { opacity: 1; }
}
